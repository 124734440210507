import type { PossibleTypesMap } from '@apollo/client';
import { InMemoryCache } from '@apollo/client';
import type { Static } from 'runtypes';
import { Array, Record, String } from 'runtypes';
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist';

const ObjectType = Record({ name: String });

const IntrospectionSuperType = Record({
  name: String,
  possibleTypes: Array(ObjectType),
});

export const IntrospectionResultSchema = Record({
  __schema: Record({
    types: Array(IntrospectionSuperType),
  }),
});

export type ResultSchema = Static<typeof IntrospectionResultSchema>;

export function mutatePossibleTypeMetaData(introspection: ResultSchema) {
  const possibleTypes: PossibleTypesMap = {};

  for (const supertype of introspection.__schema.types) {
    if (!supertype.possibleTypes) {
      continue;
    }

    possibleTypes[supertype.name] = supertype.possibleTypes.map((subtype) => {
      return subtype.name;
    });
  }
  return possibleTypes;
}

export async function createCache<T extends ResultSchema>({
  schema,
  cachePersistKey,
  cachePersistPurgeOnStartupKey,
}: {
  schema: T;
  cachePersistKey?: string;
  cachePersistPurgeOnStartupKey?: string;
}) {
  IntrospectionResultSchema.guard(schema);
  const inMemoryCache = new InMemoryCache({
    possibleTypes: mutatePossibleTypeMetaData(schema),
  });

  if (!cachePersistKey) {
    return {
      cache: inMemoryCache,
    };
  }

  if (cachePersistPurgeOnStartupKey) {
    if (localStorage.getItem(cachePersistPurgeOnStartupKey)) {
      localStorage.removeItem(cachePersistKey);
      localStorage.removeItem(cachePersistPurgeOnStartupKey);
    }
  }

  /**
   * TODO: The long term best solution is to accept queriesToCache prop, parse and use it to filter the things we want to persist.
   * It'll be way simpler conceptually but requires a lot of tedious work digging into gql document structure so just needs a lot of time.
   * */

  const persistor = new CachePersistor({
    cache: inMemoryCache,
    storage: new LocalStorageWrapper(window.localStorage),
    // Spamming any below because the types are a complete mess
    persistenceMapper: (data: any) => {
      /**
       * This is the complete, in-memory apollo cache object.
       * Our job is to strip away everything except for the
       *  things we want to persist. This includes nested filtering
       *  of objects if necessary.
       *
       * We don't have to worry about the performance of this function
       *  too much since the persistance happens async but even async
       *  work needs to be scheduled at some point so keep that in mind.
       * */

      const apolloCacheObject = JSON.parse(data);

      /**
       * Cache persistance is a 2 step process, we need to know which queries to cache first
       *  and then make sure all objects those queries reference are cached as well.
       * For example we cache the book query and the object references it needs such as BookAdditionalDetails and BookSettings.
       *
       * This process IS manual and tedious so don't rush through it if you ever need to look at it.
       */

      let persistedRootQuery = {};
      if ('ROOT_QUERY' in apolloCacheObject) {
        persistedRootQuery = {
          ...Object.keys(apolloCacheObject.ROOT_QUERY)
            .filter((key) => {
              if (
                key === 'authenticatedUser' ||
                key === 'userContext' ||
                /**
                 * We use startsWith for keys that are dependant on variables like bookId etc
                 *  because the keys are strings like navigationMenu({"input":{"bookId":"xxx-xxx"}})
                 */
                key.startsWith('navigationMenu(') ||
                key.startsWith('book(')
              ) {
                return true;
              }

              return false;
            })
            /**
             * Usually there's no need for deep filtering on query level because most of the time
             *  the query only stores references to the object.
             */
            .reduce((filteredRootCacheObject, key) => {
              filteredRootCacheObject[key] = apolloCacheObject.ROOT_QUERY[key];
              return filteredRootCacheObject as any;
            }, {} as any),
        };
      }

      /**
       * These objects are needed by the queries cached above.
       * If these are not in sync with the query definition, there will be chaos.
       * Whenever a cached query changes we have to make sure its associated references are in sync.
       */
      const persistedObjectReferences = Object.keys(apolloCacheObject)
        .filter((key) => {
          return (
            // #region authenticatedUser query
            key.startsWith('PortalCompany:') ||
            key.startsWith('PortalProduct:') ||
            key.startsWith('PortalSubscription:') ||
            key.startsWith('User:') ||
            //#endregion authenticatedUser query

            // #region book query
            key.startsWith('Address:') ||
            key.startsWith('Book:') ||
            key.startsWith('BookCompany:') ||
            key.startsWith('BookAdditionalDetails:') ||
            key.startsWith('BookPayrollLegalContact:') ||
            key.startsWith('BookCompanyContact:') ||
            key.startsWith('BookPayrollSettings:') ||
            key.startsWith('BookSettings:') ||
            key.startsWith('ProductTypeDetails:') ||
            key.startsWith('PayrollStpCompany:')
            //#endregion book query
          );
        })
        /**
         * Deep filtering of persisted objects to be done here on an individual basis.
         * This is only meant for objects that are partially persisted. For example we
         *  may want to persist the book.company but not book.settings.payItems.
         *
         * If you get deep filtering wrong, one of the symptoms will be you run a query
         *  once and see the results, you reload the page and you don't see the results
         *  any more. This is because you've cached part of a query that references an object
         *  but you never cached that object itself. You can verify this by looking at the
         *  partially cached object in local storage.
         */
        .reduce((returnValue, key) => {
          // #region Filter book object
          if (key.startsWith('Book:')) {
            // Should be something like Book:xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx
            const bookCacheKey = Object.keys(apolloCacheObject).find((key) => {
              return key.startsWith('Book:');
            });

            if (!bookCacheKey) {
              throw Error('Could not find book query in cache object');
            }

            const filteredBookCacheObject = Object.keys(
              apolloCacheObject[bookCacheKey]
            )
              .filter((key) => {
                // This shape pretty much matches the shape of the base state query.
                return (
                  key === '__typename' ||
                  key === 'id' ||
                  key === 'name' ||
                  key === 'country' ||
                  key === 'dateCreated' ||
                  key === 'startDate' ||
                  key === 'financialYearStartDay' ||
                  key === 'financialYearStartMonth' ||
                  key === 'company' ||
                  key === 'additionalDetails' ||
                  // We may want to further filter the settings object.
                  key === 'settings'
                );
              })
              .reduce((value, key) => {
                value[key] = apolloCacheObject[bookCacheKey][key];
                return value;
              }, {} as any);

            returnValue[key] = filteredBookCacheObject;
          }
          // #endregion Filter book object
          else {
            returnValue[key] = apolloCacheObject[key];
          }
          return returnValue;
        }, {} as any);

      const persistedPayload = {
        ...persistedObjectReferences,
        ROOT_QUERY: { ...persistedRootQuery, __typename: 'Query' },
      };

      return JSON.stringify(persistedPayload) as any;
    },
    trigger: 'write',
    key: cachePersistKey,
    debug: true,
  });

  return { cache: inMemoryCache, persistor };
}
